<template>
  <div>
    <div class="bg-gray-800 pb-32">
      <nav class="bg-gray-800">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="border-b border-gray-700">
            <div
              :class="rtl('flex-row-reverse')"
              class="flex items-center justify-between h-16 px-4 sm:px-0"
            >
              <div :class="rtl('flex-row-reverse')" class="flex items-center">
                <div :class="rtl('ml-4')" class="flex-shrink-0">
                  <img
                    aria-label="Pearson Logo"
                    class="h-8 bg-gray-800"
                    src="../assets/logo.svg"
                    alt="Pearson logo"
                  />
                </div>
                <div v-if="user && !iframe" class="hidden md:block">
                  <div
                    role="navigation"
                    :class="
                      rtl('flex-row-reverse mr-10 space-x-reverse') +
                        ltr('ml-10')
                    "
                    class="flex space-x-4 items-baseline"
                  >
                    <router-link
                      role="link"
                      aria-label="View dashboard"
                      id="dashboard-link"
                      class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      :to="{ name: 'Dashboard' }"
                      >{{ translation.buttons_links.dashboard }}</router-link
                    >
                    <router-link
                      role="link"
                      aria-label="View bookings"
                      id="bookings-link"
                      class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      :to="{ name: 'Bookings' }"
                      >{{ translation.buttons_links.bookings }}</router-link
                    >
                    <router-link
                      role="link"
                      aria-label="View invigilators"
                      id="invigilators-link"
                      class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      :to="{ name: 'Invigilators' }"
                      >{{ translation.buttons_links.invigilators }}</router-link
                    >
                    <router-link
                      role="link"
                      aria-label="Move groups"
                      id="groups-link"
                      class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      :to="{ name: 'ScheduleGroups' }"
                      >{{
                        translation.buttons_links.invigilator_groups
                      }}</router-link
                    >
                    <router-link
                      role="link"
                      aria-label="View scripts"
                      id="scripts-link"
                      class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      :to="{ name: 'Scripts' }"
                      >Access to Scripts</router-link
                    >
                    <router-link
                      v-if="showMarkSubmission()"
                      role="link"
                      aria-label="Mark submission"
                      id="marks-link"
                      class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      :to="{ name: 'Marks' }"
                      >Mark Submission</router-link
                    >
                    <router-link
                      v-if="
                        user.service_types &&
                          user.service_types.includes('feMaths')
                      "
                      role="link"
                      aria-label="FE Maths Report"
                      id="fe-maths-report-link"
                      class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      exact-active-class="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300"
                      :to="{ name: 'FeMathsReport' }"
                      >FE Maths Report</router-link
                    >
                  </div>
                </div>
              </div>
              <div>
                <div
                  :class="
                    rtl('flex-row-reverse mr-4 md:mr-6') + ltr('ml-4 md:ml-6')
                  "
                  class="items-center hidden md:flex"
                >
                  <div
                    aria-label="Your full name"
                    v-if="user"
                    class="text-white mr-4 text-sm"
                  >
                    <span v-if="user.organisation_external_id"
                      >[{{ user.organisation_external_id }}]</span
                    >
                    {{ user.firstName }} {{ user.lastName }}
                  </div>
                  <language-switcher></language-switcher>
                  <button
                    aria-label="Language switcher"
                    @click.prevent="slide_over_open = !slide_over_open"
                    class="p-1 relative border-2 border-transparent text-gray-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 focus:ring-2 focus:ring-indigo-300 flex justify-center items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                    <span
                      v-if="unreadNotifications"
                      class="absolute top-0 right-0 block h-4 w-4 rounded-full text-white shadow-solid bg-green-400 text-xs"
                    >
                      <span>{{ unreadNotifications }}</span>
                    </span>
                  </button>
                  <profile-menu
                    v-if="!iframe"
                    @logout="logout"
                    :open="profile_open"
                  ></profile-menu>
                </div>
              </div>
              <div v-if="user" class="-mr-2 flex md:hidden">
                <button
                  @click.prevent="mobile_menu_open = !mobile_menu_open"
                  class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white focus:ring-2 focus:ring-indigo-300"
                >
                  <svg
                    aria-hidden="true"
                    v-if="!mobile_menu_open"
                    class="block h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    aria-hidden="true"
                    v-if="mobile_menu_open"
                    class="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <language-switcher
                  :class="rtl('ml-3') + ltr('mr-3')"
                ></language-switcher>
              </div>
            </div>
          </div>
        </div>
        <mobile-menu
          @logout="logout"
          :iframe="iframe"
          :open="mobile_menu_open"
        ></mobile-menu>
      </nav>
      <header role="group" aria-label="Heading" class="py-10">
        <slot name="title"></slot>
        <div
          v-if="!hasTitleOverride()"
          class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <h1
            v-if="title"
            :class="rtl('text-right')"
            class="text-3xl leading-9 font-bold text-white"
          >
            {{ title }}
          </h1>
        </div>
      </header>
    </div>
    <main role="main" class="-mt-32">
      <div class="max-w-6xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <div
          class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body"
        >
          <slot name="contents"></slot>
        </div>
        <div></div>
      </div>
    </main>
    <footer
      class="bg-white border-t border-gray-100 max-w-7xl mx-auto sm:px-6 lg:px-8"
    >
      <div
        class="max-w-screen-xl mx-auto py-6 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8"
      >
        <nav class="-mx-5 -my-2 flex flex-wrap justify-center">
          <div class="px-5 py-2">
            <a
              target="pearson"
              href="https://support.pearson.com/uk/s/qualification-contactus"
              class="text-sm leading-6 text-gray-500 hover:text-gray-900"
            >
              Customer Service
            </a>
          </div>
          <div class="px-5 py-2">
            <a
              target="pearson"
              href="https://www.pearson.com/legal-information/privacy-policy.html"
              class="text-sm leading-6 text-gray-500 hover:text-gray-900"
            >
              Privacy policy
            </a>
          </div>
          <div class="px-5 py-2">
            <a
              target="pearson"
              href="https://www.pearson.com/uk/pearson-privacy-and-you/cookie-policy.html"
              class="text-sm leading-6 text-gray-500 hover:text-gray-900"
            >
              Cookie policy
            </a>
          </div>
          <div class="px-5 py-2">
            <a
              target="pearson"
              href="https://www.pearson.com/uk/legal-notice.html"
              class="text-sm leading-6 text-gray-500 hover:text-gray-900"
            >
              Legal notice
            </a>
          </div>
        </nav>
        <p class="mt-8 text-center text-xs leading-6 text-gray-400">
          © {{ date }} Pearson · {{ version }}
        </p>
      </div>
    </footer>
  </div>
  <notifications></notifications>
  <slide-over-messages
    :open="slide_over_open"
    @changed="slideOverChanged"
  ></slide-over-messages>
</template>

<script>
import gravatar from "gravatar";
import LanguageSwitcher from "../components/LanguageSwitcher";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import Notifications from "@/components/Notifications";
import SlideOverMessages from "@/components/SlideOverMessages";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    iframe: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SlideOverMessages,
    LanguageSwitcher,
    MobileMenu,
    ProfileMenu,
    Notifications,
  },
  data() {
    return {
      profile_open: false,
      mobile_menu_open: false,
      slide_over_open: false,
    };
  },
  computed: {
    date() {
      return new Date().getFullYear();
    },
    version() {
      return this.$store.state.translatables.version;
    },
    translation() {
      return this.$store.state.translatables.translation;
    },
    user() {
      return this.$store.state.user;
    },
    icon() {
      return gravatar.url(this.$store.state.user.email);
    },
    unreadNotifications() {
      return this.$store.state.messages.filter(
        notification => !notification.read,
      ).length;
    },
  },
  methods: {
    showMarkSubmission() {
      if (!this.user.service_types) {
        return false;
      }

      const hasMockOrFeMaths =
        this.user.service_types.includes("mock") ||
        this.user.service_types.includes("feMaths");
      const hasHighStake = this.user.service_types.includes("highstake");

      return !hasMockOrFeMaths || (hasHighStake && hasMockOrFeMaths);
    },
    slideOverChanged(value) {
      this.slide_over_open = value;
    },
    hasTitleOverride() {
      return !!this.$slots.title;
    },
    logout() {
      this.$global.pageIsLoading();
      this.profile_open = false;
      this.setXSRFToken(() => {
        this.$axios
          .post(this.$global.endpoint("/sanctum/users/revoke"), {})
          .then(() => {
            this.$store.commit("clear");
            this.$router.push("/login");
          })
          .catch(() => {
            this.$store.commit("clear");
            this.$router.push("/login");
          });
      });
    },
    setXSRFToken(callback) {
      this.$axios
        .get(this.$global.endpoint("/sanctum/csrf-cookie"))
        .then(callback);
    },
    route(route) {
      this.$router.push(route);
    },
  },
};
</script>
